import React, { useCallback, useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton, Table, Tbody, Thead, Tr, Th, Td, Heading, Center, Box, Text, Spacer, Button } from "@chakra-ui/react";
import axios from "axios";
import { CheckIcon, DownloadIcon, NotAllowedIcon, RepeatIcon } from "@chakra-ui/icons";
import { useAuth } from "../../context/AuthContext";
const Synthese = () => {
  const [data, setData] = useState([]);
  const [statG, setStatG] = useState([]);
  const [statS, setStatS] = useState([]);
  const {isAdmin, user} = useAuth();
  const [perm, setPerm] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  
  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_}/docs_s`);
      const dataToSet = isAdmin ? response.data : response.data.filter(doc => perm.some(p => p.lycee_id === doc.lycee_id));
      setData(groupDataBySecteurLyceeAndInter(dataToSet));

      const statsResponse = await axios.get(`${process.env.REACT_APP_API_URL_}/docs_stats`);
      setStatG(statsResponse.data.global);
      setStatS(statsResponse.data["secteurs"]);
    } catch (error) {
      console.error("Une erreur est survenue", error);
    }
  }, [isAdmin, perm]);

  const fetchReceiver = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_}/user/${user.user_id}/perm`);
      setPerm(response.data);
    } catch (error) {
      console.error("Error fetching receiver data", error);
    }
  }, [user]);

  useEffect(() => {
    fetchReceiver();
  }, [fetchReceiver]);

  useEffect(() => {
    if (perm.length > 0 || isAdmin) {
      fetchData();
    }
  }, [perm, isAdmin, fetchData]);

  const groupDataBySecteurLyceeAndInter = (rawData) => {
    const groupedData = {};
    rawData.forEach((item) => {
      const secteurName = item.secteur;
      const lyceeName = item.lycee;
      const interventionName = item.intervention;
      if (!groupedData[secteurName]) groupedData[secteurName] = {};
      if (!groupedData[secteurName][lyceeName]) groupedData[secteurName][lyceeName] = {};
      if (!groupedData[secteurName][lyceeName][interventionName]) groupedData[secteurName][lyceeName][interventionName] = [];
      groupedData[secteurName][lyceeName][interventionName].push(item);
    });
    return groupedData;
  };

  const handleDownload = async (docID, fileName) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL_}/docs/${docID}/download`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (ex) {
      console.log(ex);
    }
  };

  const renderTableRow = (lyceeData) => (
    <Tr key={lyceeData.document_id}>
      <Td w='10%'>{lyceeData.nom}</Td>
      <Td w={'10%'}>{formatDate(lyceeData.sending_date)}</Td>
      <Td w={'10%'}>{formatDate(lyceeData.desired_date)}</Td>
      <Td w={'10%'}>{lyceeData.category}</Td>
      <Td w={'10%'}>{lyceeData.status} </Td>
      <Td w={'10%'}>
        {lyceeData.ApprovedBy ? `${lyceeData.ApprovedBy?.first_name} ${lyceeData.ApprovedBy?.last_name}` : null}
      </Td>
      <Td w={'10%'}>
        {lyceeData.approuved_date ? formatDate(lyceeData.approuved_date) : null}
      </Td>
      <Td w={'10%'}>
        <Button variant={'ghost'} onClick={() => handleDownload(lyceeData.document_id, lyceeData.file_path)}>
          <DownloadIcon />
        </Button>
      </Td>
    </Tr>
  );

  const renderTableSection = (secteurName, lyceeName, interventionName) => (
    <AccordionItem key={`${lyceeName}-${interventionName}`}>
      <AccordionButton>
        <AccordionIcon />
        {lyceeName}
        <Spacer />
        <Text color={"GrayText"}>
          <CheckIcon mr={1} ml={1} /> {statS[secteurName]?.lycees?.[lyceeName]?.['Accepté'] || 0}
          <NotAllowedIcon mr={1} ml={1} /> {statS[secteurName]?.lycees?.[lyceeName]?.['Refusé'] || 0}
          <RepeatIcon ml={1} /> {statS[secteurName]?.lycees?.[lyceeName]?.['En attente'] || 0}
          <Text as='b'> Total </Text> {statS[secteurName]?.lycees?.[lyceeName]?.Total || 0}
        </Text>
      </AccordionButton>
      <AccordionPanel>
        <Accordion allowMultiple>
          <AccordionItem key={`${lyceeName}-${interventionName}`}>
            <AccordionButton>
              <AccordionIcon />
              {interventionName}
              <Spacer />
              <Text color={"GrayText"}>
                <CheckIcon mr={1} ml={1} /> {statS[secteurName]?.lycees?.[lyceeName]?.interventions?.[interventionName]?.['Accepté'] || 0}
                <NotAllowedIcon mr={1} ml={1} /> {statS[secteurName]?.lycees?.[lyceeName]?.interventions?.[interventionName]?.['Refusé'] || 0}
                <RepeatIcon ml={1} /> {statS[secteurName]?.lycees?.[lyceeName]?.interventions?.[interventionName]?.['En attente'] || 0}
                <Text as='b'> Total </Text> {statS[secteurName]?.lycees?.[lyceeName]?.interventions?.[interventionName]?.Total || 0}
              </Text>
            </AccordionButton>
            <AccordionPanel>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Nom</Th>
                    <Th>Date d'envoi</Th>
                    <Th>Echéance de validation souhaitée</Th>
                    <Th>Catégorie</Th>
                    <Th>statut</Th>
                    <Th> Approuvé par </Th>
                    <Th> Date d'approbation</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data[secteurName][lyceeName][interventionName].map(renderTableRow)}
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </AccordionPanel>
    </AccordionItem>
  );
  

  return (
    <>
      <Center>
        <Heading as="h1" size="lg" mb={5}>Synthèse des documents</Heading>
      </Center>
      <Box w={'70%'} ml="auto" mr="auto">
        {isAdmin && (
          <Text>
            Pour un total de {statG.Total} documents, il y a {statG.Accepté} documents acceptés, {statG["En attente"]} documents en attente et {statG.Refusé} documents refusés.
          </Text>
        )}
        <Accordion allowMultiple>
          {Object.keys(data).map((secteurName) => (
            <AccordionItem key={secteurName}>
              <AccordionButton>
                <AccordionIcon />
                {secteurName}
                <Spacer />
                {isAdmin && (
                  <Text color={"GrayText"}>
                    <CheckIcon mr={1} ml={1} /> {statS[secteurName]?.['Accepté']}
                    <NotAllowedIcon mr={1} ml={1} /> {statS[secteurName]?.['Refusé']}
                    <RepeatIcon ml={1} /> {statS[secteurName]?.['En attente']}
                    <Text as='b'> Total {statS[secteurName]?.Total}</Text>
                  </Text>
                )}
              </AccordionButton>
              <AccordionPanel>
                <Accordion allowMultiple>
                  {Object.keys(data[secteurName]).map((lyceeName) => (
                    Object.keys(data[secteurName][lyceeName]).map((interventionName) =>
                      renderTableSection(secteurName, lyceeName, interventionName)
                    )
                  ))}
                </Accordion>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </>
  );
};

export default Synthese;
