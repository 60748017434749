export function formatDate(dateString) {
    if (dateString === null) {
      return "Non renseigné";
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }
  
  export function renderInterventionEtat(etat) {
    switch (etat) {
      case 0:
        return "En attente d'intervention";
      case 1:
        return "En cours d'intervention";
      case 2:
        return "Intervention clôturée sans validation";
      case 3:
        return "Intervention clôturée avec validation";
      case 4:
        return "Intervention refusée";
      default:
        return "Non renseigné";
    }
  }
  
  export function renderInterventionEtatProgress(etat) {
    switch (etat) {
      case 0:
        return 25;
      case 1:
        return 50;
      case 2:
        return 75;
      case 3:
        return 100;
      case 4:
        return 100;
      default:
        return 0;
    }
  }
  